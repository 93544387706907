import axios from "axios";
import { ensureArray } from "../helpers/typeChecking";

const createApiConfig = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
};
const createFullURL = (
  route = "",
  endpoint = "",
  authType = "user",
  params = [],
  version = "v1"
) => {
  const paramsString =  ensureArray(params)
    .map((param) => `/${param}`)
    .join("");
  

  if (
    !["user", "advisor", "admin", "learning_lab", 'manager',"blog", "none"].includes(
      authType
    )
  ) {
    throw new Error(
      "Auth Type must be one of: user, advisor, admin, learning_lab, manager, blog, none"
    );
  }

  if (route === null || endpoint === null || !version) {
    throw new Error("Route, endpoint, and version are required");
  }

  const baseURL =
    process.env.NODE_ENV === "production" ? "/" : "http://localhost:5000/";

  return `${baseURL}api/${version}/${route}/${endpoint}_auth_${authType}${paramsString}`;
};

const maxAttempts = 5;
export const internalGetReq = async (
  authType,
  route,
  endpoint,
  params = [],
  version = "v1",
  fullRequest = false
) => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const fullURL = createFullURL(route, endpoint, authType, params, version);
      const apiConfig = createApiConfig();
      const response = await axios.get(fullURL, apiConfig);
      return fullRequest? response : response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        attempts++;
        console.error(`Attempt ${attempts} after 500-level error:`, error.message);
        if (attempts >= maxAttempts) {
          console.error("Max retry attempts reached after server errors. Aborting...");
          throw error;
        }
      } else {
        // If the error is not a 500-level error, rethrow immediately
        throw error;
      }
    }
  }
};

export const internalPostReq = async (
  authType,
  route,
  endpoint,
  body = {},
  params = [],
  version = "v1"
) => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const fullURL = createFullURL(route, endpoint, authType, params, version);
      const apiConfig = createApiConfig();
      const { data } = await axios.post(fullURL, body, apiConfig);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        attempts++;
        console.error(`Attempt ${attempts} after 500-level error:`, error.message);
        if (attempts >= maxAttempts) {
          console.error("Max retry attempts reached after server errors. Aborting...");
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
};

export const internalPatchReq = async (
  authType,
  route,
  endpoint,
  body = {},
  params = [],
  version = "v1"
) => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const fullURL = createFullURL(route, endpoint, authType, params, version);
      const apiConfig = createApiConfig();
      const { data } = await axios.patch(fullURL, body, apiConfig);
      console.log("Patch response:", data);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        attempts++;
        console.error(`Attempt ${attempts} after 500-level error:`, error.message);
        if (attempts >= maxAttempts) {
          console.error("Max retry attempts reached after server errors. Aborting...");
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
};


export const internalDeleteReq = async (
  authType,
  route,
  endpoint,
  params = [],
  version = "v1"
) => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const fullURL = createFullURL(route, endpoint, authType, params, version);
      const apiConfig = createApiConfig();
      const { data } = await axios.delete(fullURL, apiConfig);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        attempts++;
        console.error(`Attempt ${attempts} after 500-level error:`, error.message);
        if (attempts >= maxAttempts) {
          console.error("Max retry attempts reached after server errors. Aborting...");
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
};
